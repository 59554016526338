export default [
    {
        name:'pi pi-align-center'
    },
    {
        name:'pi pi-align-justify'
    },
    {
        name:'pi pi-align-left'
    },
    {
        name:'pi pi-align-right'
    },
    {
        name:'pi pi-amazon'
    },
    {
        name:'pi pi-android'
    },
    {
        name:'pi pi-angle-double-down'
    },
    {
        name:'pi pi-angle-double-left'
    },
    {
        name:'pi pi-angle-double-right'
    },
    {
        name:'pi pi-angle-double-up'
    },
    {
        name:'pi pi-angle-down'
    },
    {
        name:'pi pi-angle-left'
    },
    {
        name:'pi pi-angle-right'
    },
    {
        name:'pi pi-angle-up'
    },
    {
        name:'pi pi-apple '
    },
    {
        name:'pi pi-arrow-circle-down'
    },
    {
        name:'pi pi-arrow-circle-left'
    },
    {
        name:'pi pi-arrow-circle-right'
    },
    {
        name:'pi pi-arrow-circle-up'
    },
    {
        name:'pi pi-arrow-down'
    },
    {
        name:'pi pi-arrow-left'
    },
    {
        name:'pi pi-arrow-right'
    },
    {
        name:'pi pi-arrow-up'
    },
    {
        name:'pi pi-backward'
    },
    {
        name:'pi pi-ban'
    },
    {
        name:'pi pi-bell'
    },
    {
        name:'pi pi-book'
    },
    {
        name:'pi pi-bookmark'
    },
    {
        name:'pi pi-briefcase'
    },
    {
        name:'pi pi-calendar'
    },
    {
        name:'pi pi-calendar-minus'
    },
    {
        name:'pi pi-calendar-plus'
    },
    {
        name:'pi pi-calendar-times'
    },
    {
        name:'pi pi-camera'
    },
    {
        name:'pi pi-caret-down'
    },
    {
        name:'pi pi-caret-left'
    },
    {
        name:'pi pi-caret-right'
    },
    {
        name:'pi pi-caret-up'
    },
    {
        name:'pi pi-chart-bar'
    },
    {
        name:'pi pi-chart-line'
    },
    {
        name:'pi pi-check'
    },
    {
        name:'pi pi-check-circle'
    },
    {
        name:'pi pi-check-square'
    },
    {
        name:'pi pi-chevron-circle-down'
    },
    {
        name:'pi pi-chevron-circle-left'
    },
    {
        name:'pi pi-chevron-circle-right'
    },
    {
        name:'pi pi-chevron-circle-up'
    },
    {
        name:'pi pi-sitemap'
    },
    {
        name:'pi pi-send'
    },
    {
        name:'pi pi-users'
    },
    {
        name:'pi pi-user'
    }
]